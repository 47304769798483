import React, { useState, useContext, useEffect } from 'react';
import { Container, Typography, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Grid, CircularProgress } from '@mui/material';
import { ServerContext } from '../context/ServerContext';
import { format } from 'date-fns';
import { fetchRequests } from '../api/apiService'; // Import the dummy API service

function AdminPage() {
  const { serverAvailability, setServerAvailability } = useContext(ServerContext);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch requests from the dummy API service
    fetchRequests().then(fetchedRequests => {
      setRequests(fetchedRequests);
      setLoading(false);
    });
  }, []);

  const handleConfirm = (request) => {
    const updatedRequests = requests.map((req) =>
      req.id === request.id ? { ...req, status: 'Confirmed' } : req
    );
    setRequests(updatedRequests);
    updateServerAvailability(request, true);
  };

  const handleReject = (request) => {
    const updatedRequests = requests.map((req) =>
      req.id === request.id ? { ...req, status: 'Rejected' } : req
    );
    setRequests(updatedRequests);
  };

  const updateServerAvailability = (request, isConfirmed) => {
    if (!isConfirmed) return;

    const startDateStr = format(request.startDate, 'yyyy-MM-dd');
    const updatedAvailability = { ...serverAvailability };
    const serverId = request.server; // Assuming server is the server name, you may need to map this to server ID

    if (!updatedAvailability[serverId]) {
      updatedAvailability[serverId] = {};
    }

    if (!updatedAvailability[serverId][startDateStr]) {
      updatedAvailability[serverId][startDateStr] = Array(24).fill(true); // Initialize with all hours available
    }

    for (let hour = request.startHour; hour < request.endHour; hour++) {
      updatedAvailability[serverId][startDateStr][hour] = false;
    }

    setServerAvailability(updatedAvailability);
  };

  return (
    <Container>
      <Box mt={5} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Admin - Manage Requests
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Server</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Start Hour</TableCell>
                  <TableCell>End Hour</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requests.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>{request.server}</TableCell>
                    <TableCell>{request.startDate.toDateString()}</TableCell>
                    <TableCell>{request.endDate.toDateString()}</TableCell>
                    <TableCell>{request.startHour}:00</TableCell>
                    <TableCell>{request.endHour}:00</TableCell>
                    <TableCell>{request.status}</TableCell>
                    <TableCell>
                      {request.status === 'Pending' && (
                        <Grid container spacing={1}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleConfirm(request)}
                            >
                              Confirm
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleReject(request)}
                            >
                              Reject
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                      {request.status !== 'Pending' && (
                        <Typography variant="body2" color={request.status === 'Confirmed' ? 'green' : 'red'}>
                          {request.status}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Container>
  );
}

export default AdminPage;
