// HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';

function HomePage() {
  return (
    <Container>
      <Box mt={5} textAlign="center">
        <Typography variant="h2" gutterBottom>
          Welcome to Micro Cloud Automation
        </Typography>
        <Box mt={3}>
          <Button variant="contained" color="primary" component={Link} to="/clouddemo/calendar">
            View Calendar
          </Button>
          <Button variant="contained" color="secondary" component={Link} to="/clouddemo/request" sx={{ ml: 2 }}>
            Request Servers
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default HomePage;
