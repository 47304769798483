// ConfirmationPage.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function ConfirmationPage() {
  return (
    <Container>
      <Box mt={5} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Reservation Confirmation
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your reservation has been confirmed. The admin will contact you for further details.
        </Typography>
      </Box>
    </Container>
  );
}

export default ConfirmationPage;
