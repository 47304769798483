import React, { createContext, useState, useEffect } from 'react';
import { addDays, format } from 'date-fns';

const ServerContext = createContext();

const servers = [
  { id: 1, name: 'Server 1' },
  { id: 2, name: 'Server 2' },
  { id: 3, name: 'Server 3' },
];

const generateRandomAvailability = () => {
  const availability = {};
  for (let i = 0; i < 24; i++) {
    availability[i] = Math.random() > 0.5; // Randomly mark hours as available or not
  }
  return availability;
};

const fetchServerAvailability = () => {
  const availability = {};
  const today = new Date();
  for (let i = 0; i < 180; i++) { // 180 days for 6 months
    const dateStr = format(addDays(today, i), 'yyyy-MM-dd');
    availability[dateStr] = generateRandomAvailability();
  }

  const serverAvailability = {};
  servers.forEach(server => {
    serverAvailability[server.id] = { ...availability };
  });
  return serverAvailability;
};

const ServerProvider = ({ children }) => {
  const [serverAvailability, setServerAvailability] = useState({});

  useEffect(() => {
    const availability = fetchServerAvailability();
    console.log('Generated availability:', availability); // Log availability data
    setServerAvailability(availability);
  }, []);

  return (
    <ServerContext.Provider value={{ servers, serverAvailability, setServerAvailability }}>
      {children}
    </ServerContext.Provider>
  );
};

export { ServerContext, ServerProvider };
