import React, { useState, useContext, useEffect } from 'react';
import { Container, Typography, Box, Select, MenuItem, FormControl, InputLabel, TextField, Button, Grid, FormHelperText } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate, useLocation } from 'react-router-dom';
import { ServerContext } from '../context/ServerContext';
import { format } from 'date-fns';

function RequestForm() {
  const { servers, serverAvailability, setServerAvailability } = useContext(ServerContext);
  const [formData, setFormData] = useState({
    server: '',
    startDate: null,
    endDate: null,
    startHour: '',
    endHour: '',
    serverCount: 1,
  });
  const [errors, setErrors] = useState({});
  const [availabilityError, setAvailabilityError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setFormData(location.state);
    }
  }, [location.state]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: '',
    }));
    setAvailabilityError('');
  };

  const handleDateChange = (name, date) => {
    setFormData({
      ...formData,
      [name]: date,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
    setAvailabilityError('');
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.server) newErrors.server = 'Server is required';
    if (!formData.startDate) newErrors.startDate = 'Start date is required';
    if (formData.startHour === '') newErrors.startHour = 'Start hour is required';
    if (formData.endHour === '') newErrors.endHour = 'End hour is required';
    if (formData.serverCount < 1) newErrors.serverCount = 'At least one server is required';

    if (formData.endDate && formData.startDate && formData.endDate < formData.startDate) {
      newErrors.endDate = 'End date must be equal to or after start date';
    }
    if (formData.startHour !== '' && formData.endHour !== '' && parseInt(formData.endHour) <= parseInt(formData.startHour)) {
      newErrors.endHour = 'End hour must be after start hour';
    }

    return newErrors;
  };

  const checkAvailability = () => {
    const startDateStr = format(formData.startDate, 'yyyy-MM-dd');
    const availability = serverAvailability[formData.server];

    if (!availability || !availability[startDateStr]) return false;

    for (let hour = parseInt(formData.startHour); hour < parseInt(formData.endHour); hour++) {
      if (!availability[startDateStr][hour]) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      if (checkAvailability()) {
        const startDateStr = format(formData.startDate, 'yyyy-MM-dd');
        const updatedAvailability = { ...serverAvailability };

        for (let hour = parseInt(formData.startHour); hour < parseInt(formData.endHour); hour++) {
          updatedAvailability[formData.server][startDateStr][hour] = false;
        }

        setServerAvailability(updatedAvailability);
        navigate('/clouddemo/confirmation');
      } else {
        setAvailabilityError('The selected server is not available during the specified time range.');
      }
    }
  };

  const today = new Date();

  return (
    <Container>
      <Box mt={5} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Request Servers
        </Typography>
        {availabilityError && (
          <Box mb={3}>
            <Typography color="error">{availabilityError}</Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate('/clouddemo/calendar', { state: formData })}
            >
              Check Availability
            </Button>
          </Box>
        )}
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth sx={{ mt: 3 }} error={!!errors.server}>
            <InputLabel id="server-select-label">
              Select Server <Typography component="span" color="error">*</Typography>
            </InputLabel>
            <Select
              labelId="server-select-label"
              name="server"
              value={formData.server}
              onChange={handleChange}
              label="Select Server"
            >
              {servers.map((server) => (
                <MenuItem key={server.id} value={server.id}>
                  {server.name}
                </MenuItem>
              ))}
            </Select>
            {errors.server && <FormHelperText>{errors.server}</FormHelperText>}
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box mt={3}>
              <DatePicker
                label="Start Date *"
                value={formData.startDate}
                onChange={(date) => handleDateChange('startDate', date)}
                minDate={today}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!errors.startDate}
                  />
                )}
              />
              {errors.startDate && (
                <FormHelperText error sx={{ textAlign: 'center' }}>
                  {errors.startDate}
                </FormHelperText>
              )}
            </Box>
            <Box mt={3}>
              <DatePicker
                label="End Date"
                value={formData.endDate}
                onChange={(date) => handleDateChange('endDate', date)}
                minDate={today}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!errors.endDate}
                  />
                )}
              />
              {errors.endDate && (
                <FormHelperText error sx={{ textAlign: 'center' }}>
                  {errors.endDate}
                </FormHelperText>
              )}
            </Box>
          </LocalizationProvider>
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Start Hour *"
                  type="number"
                  name="startHour"
                  value={formData.startHour}
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0, max: 23 } }}
                  fullWidth
                  error={!!errors.startHour}
                  helperText={errors.startHour}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="End Hour *"
                  type="number"
                  name="endHour"
                  value={formData.endHour}
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0, max: 23 } }}
                  fullWidth
                  error={!!errors.endHour}
                  helperText={errors.endHour}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <TextField
              label="Number of Servers *"
              type="number"
              name="serverCount"
              value={formData.serverCount}
              onChange={handleChange}
              InputProps={{ inputProps: { min: 1 } }}
              fullWidth
              margin="normal"
              error={!!errors.serverCount}
              helperText={errors.serverCount}
            />
          </Box>
          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit Request
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
}

export default RequestForm;
