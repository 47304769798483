import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import HomePage from './components/HomePage';
import Calendar from './components/Calendar';
import RequestForm from './components/RequestForm';
import ConfirmationPage from './components/ConfirmationPage';
import AdminDashboard from './components/AdminDashboard';
import { ServerProvider } from './context/ServerContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#ac4e4d',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ServerProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route path="/clouddemo" element={<HomePage />} />
              <Route path="/clouddemo/calendar" element={<Calendar />} />
              <Route path="/clouddemo/request" element={<RequestForm />} />
              <Route path="/clouddemo/confirmation" element={<ConfirmationPage />} />
              <Route path="/clouddemo/admin" element={<AdminDashboard />} />
            </Routes>
          </div>
        </Router>
      </ServerProvider>
    </ThemeProvider>
  );
}

export default App;
