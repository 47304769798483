// src/services/apiService.js

export const fetchRequests = () => {
  // Simulate a delay to mimic an API call
  return new Promise((resolve) => {
    setTimeout(() => {
      const requests = [
        {
          id: 1,
          server: 'Server 1',
          startDate: new Date('2024-07-25'),
          endDate: new Date('2024-07-25'),
          startHour: 9,
          endHour: 17,
          status: 'Pending'
        },
        {
          id: 2,
          server: 'Server 2',
          startDate: new Date('2024-07-26'),
          endDate: new Date('2024-07-26'),
          startHour: 10,
          endHour: 15,
          status: 'Pending'
        }
      ];
      resolve(requests);
    }, 1000);
  });
};
