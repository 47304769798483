import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, Box, Select, MenuItem, FormControl, InputLabel, Grid, Paper, TextField, Button } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ServerContext } from '../context/ServerContext';

function Calendar() {
  const { servers, serverAvailability } = useContext(ServerContext);
  const [selectedServer, setSelectedServer] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [hourlyAvailability, setHourlyAvailability] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state) {
      setSelectedServer(location.state.server);
      setSelectedDate(location.state.date);
      const startDateStr = location.state.date ? format(location.state.date, 'yyyy-MM-dd') : '';
      if (serverAvailability[location.state.server] && serverAvailability[location.state.server][startDateStr]) {
        setHourlyAvailability(Object.values(serverAvailability[location.state.server][startDateStr]));
        console.log('Availability on load:', serverAvailability[location.state.server][startDateStr]); // Log availability on load
      }
    }
  }, [location.state, serverAvailability]);

  const handleServerChange = (event) => {
    setSelectedServer(event.target.value);
    setSelectedDate(null);
    setHourlyAvailability([]);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const startDateStr = date ? format(date, 'yyyy-MM-dd') : '';
    if (serverAvailability[selectedServer] && serverAvailability[selectedServer][startDateStr]) {
      setHourlyAvailability(Object.values(serverAvailability[selectedServer][startDateStr]));
      console.log('Availability on date change:', serverAvailability[selectedServer][startDateStr]); // Log availability on date change
    } else {
      setHourlyAvailability([]);
    }
  };

  const today = new Date();

  return (
    <Container>
      <Box mt={5} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Server Availability Calendar
        </Typography>
        {location.state && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/clouddemo/request', { state: location.state })}
            sx={{ mb: 3 }}
          >
            Back to Request
          </Button>
        )}
        <FormControl fullWidth sx={{ mt: 3 }}>
          <InputLabel id="server-select-label">Select Server</InputLabel>
          <Select
            labelId="server-select-label"
            value={selectedServer}
            onChange={handleServerChange}
            label="Select Server"
          >
            {servers.map((server) => (
              <MenuItem key={server.id} value={server.id}>
                {server.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedServer && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box mt={5}>
              <DatePicker
                label="Select Date"
                value={selectedDate}
                onChange={handleDateChange}
                minDate={today}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>
          </LocalizationProvider>
        )}
        {selectedDate && (
          <Box mt={5}>
            <Typography variant="h6" gutterBottom>
              Hourly Availability for {selectedDate.toDateString()}
            </Typography>
            <Grid container spacing={1}>
              {hourlyAvailability.length > 0 ? (
                hourlyAvailability.map((available, index) => (
                  <Grid item xs={2} key={index}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: 2,
                        backgroundColor: available ? 'green' : 'red',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    >
                      {index}:00
                    </Paper>
                  </Grid>
                ))
              ) : (
                <Typography variant="body1" color="error">
                  No availability data for the selected date.
                </Typography>
              )}
            </Grid>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default Calendar;
